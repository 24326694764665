
import { Component, Vue } from "vue-property-decorator";
import { dispatchGetUsers, dispatchUpdateUser } from "@/store/admin/actions";
import { readToken } from "@/store/main/getters";
import { api } from "@/api";

@Component
export default class ManageWorkspaceSubscriptions extends Vue {
  public email: string = "";
  public dialogUpdateSubscription = false;
  public dialogAddPrivatePlan = false;
  public dialogRemovePrivatePlan = false;
  public workspaceId: number = 1;
  public planId: number;
  public workspaceList: any[] = [];
  public privateList: any[] = [];
  public currentWorkspaceSubscriptionPlan: string = "";
  public dialogWorkspacePlans: boolean = false;

  public error: string = "";

  public async mounted() {
    await dispatchGetUsers(this.$store);
  }
  get privateHeaders() {
    return [
      {
        text: "Subscription plans",
        align: "left",
        sortable: false,
        value: "name",
      },
      { text: "Id", value: "id" },
      { text: "Active", value: "active" },
      { text: "Nickname", value: "nickname" },
      { text: "max_num_inf", value: "max_num_inf" },
      { text: "stripe_flat_price", value: "stripe_flat_price" },
      { text: "stripe_inference_price", value: "stripe_inference_price" },
      { text: "stripe_price_id_flat", value: "stripe_price_id_flat" },
      { text: "stripe_price_id_inference", value: "stripe_price_id_inference" },
      { text: "plan_period", value: "plan_period" },
      { text: "paid", value: "paid" },
      { text: "premium_model", value: "premium_model" },
      { text: "private", value: "private" },
      { text: "num_models", value: "num_models" },
      { text: "num_datasets", value: "num_datasets" },
      { text: "dataset_size", value: "dataset_size" },
    ];
  }
  get workspaceHeaders() {
    return [
      {
        text: "Workspace id",
        align: "left",
        sortable: false,
        value: "workspace_id",
      },
      { text: "user id", value: "user_id" },
      { text: "role", value: "role" },
      { text: "name", value: "name" },
    ];
  }

  private async getWorkspaces() {
    try {
      const miniMail = this.email;
      const res = await api.getUserByEmail(this.token, miniMail);
      this.workspaceList = res.data;
    } catch (err) {
      const error = err as any;
      console.log(error.response.data.detail);
    }
  }

  private async getPrivatePlans() {
    try {
      const res = await api.getPrivatePlansByWorkspace(this.token, this.workspaceId);
      this.privateList = res.data;
      const res2 = await api.getSubscription(this.token, this.workspaceId);
      this.currentWorkspaceSubscriptionPlan = res2.data.plan;
      this.dialogWorkspacePlans = true;
    } catch (err) {
      const error = err as any;
      console.log(error.response.data.detail);
    }
  }

  private async updateSubscriptionPlan() {
    this.error = "";
    try {
      const res = await api.updateWorkspaceSubscriptionPlan(
        this.token,
        this.planId,
        this.workspaceId,
      );
      this.getPrivatePlans();
    } catch (err) {
      const error = err as any;
      console.log(error.response.data.detail);
      this.error = error.response.data.detail;
    }
  }

  private async AddPrivatePlanToWorkspace() {
    this.error = "";
    try {
      const res = await api.AddPrivatePlanToWorkspace(this.token, this.planId, this.workspaceId);
      this.getPrivatePlans();
    } catch (err) {
      const error = err as any;
      console.log(error.response.data.detail);
      this.error = error.response.data.detail;
    }
  }
  private async RemovePrivatePlanFromWorkspace() {
    this.error = "";
    try {
      const res = await api.RemovePrivatePlanFromWorkspace(
        this.token,
        this.planId,
        this.workspaceId,
      );

      this.getPrivatePlans();
    } catch (err) {
      const error = err as any;
      console.log(error.response.data.detail);
      this.error = error.response.data.detail;
    }
  }

  get token() {
    return readToken(this.$store);
  }

  private toggler(dialog) {
    if (dialog === "remove") {
      this.dialogRemovePrivatePlan = true;
      this.dialogAddPrivatePlan = false;
      this.dialogUpdateSubscription = false;
    } else if (dialog === "add") {
      this.dialogRemovePrivatePlan = false;
      this.dialogAddPrivatePlan = true;
      this.dialogUpdateSubscription = false;
    } else {
      this.dialogRemovePrivatePlan = false;
      this.dialogAddPrivatePlan = false;
      this.dialogUpdateSubscription = true;
    }
  }
}
