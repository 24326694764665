
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
} from "@/interfaces";
import {
  readMetrics,
  readModels,
  readItems,
  readLabelsByIds,
  readPreviewItems,
  readPreviewHeader,
  readItem,
  readModel,
  readPredictions,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readFirstLabelContainer,
  readTarget,
  readLabels,
} from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetConfusionMatrixItems,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetRecommendation,
} from "@/store/model/actions";

import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";

import ItemPreview from "@/components/ItemPreview.vue";
import ItemPreviewConfusionMatrix from "@/components/ItemPreviewConfusionMatrix.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
    ItemPreviewConfusionMatrix,
  },
})
export default class Preview extends Vue {
  public id: number = 1;
  public metricThreshold: number = 0.5;
  public preview: boolean = true;
  public loading: boolean = false;
  public targetItems: IItemUpdate[] = [];
  public chosenLabel: number = 0;
  public previewItems: any[] = [];

  get isAdmin() {
    return readHasAdminAccess;
  }

  public async mounted() {
    const train =
      this.$router.currentRoute.query.train === undefined ||
      this.$router.currentRoute.query.train === "false"
        ? false
        : true;
    await dispatchGetConfusionMatrixItems(this.$store, {
      modelId: +this.$router.currentRoute.params.id,
      actualLabelId: +this.$router.currentRoute.query.actual,
      predictedLabelId: +this.$router.currentRoute.query.predicted,
      threshold: +this.$router.currentRoute.query.threshold,
      train,
    }).then(() => {
      this.allPreviewItems();
    });
  }

  public async trigger(switchLabel, type) {
    const train =
      this.$router.currentRoute.query.train === undefined ||
      this.$router.currentRoute.query.train === "false"
        ? false
        : true;

    if (type === true) {
      this.chosenLabel = switchLabel;
      // Case: actual was the label we look at in the metrics (/metrics/X), but predicted was other
      await dispatchGetConfusionMatrixItems(this.$store, {
        modelId: +this.$router.currentRoute.params.id,
        actualLabelId: +this.$router.currentRoute.query.actual,
        predictedLabelId: +this.chosenLabel,
        threshold: +this.$router.currentRoute.query.threshold,
        train,
      }).then(() => {
        this.allPreviewItems();
      });
    } else {
      this.chosenLabel = switchLabel;
      // Case: Predicated was the label we look at in the metrics, but actual was other
      await dispatchGetConfusionMatrixItems(this.$store, {
        modelId: +this.$router.currentRoute.params.id,
        actualLabelId: +this.chosenLabel,
        predictedLabelId: +this.$router.currentRoute.query.predicted,
        threshold: +this.$router.currentRoute.query.threshold,
        train,
      }).then(() => {
        this.allPreviewItems();
      });
    }
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  get actual() {
    return this.labelsByIds[+this.$router.currentRoute.query.actual];
  }

  get predicted() {
    return this.labelsByIds[+this.$router.currentRoute.query.predicted];
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelsByIds() {
    return readLabelsByIds(this.$store)(+this.$router.currentRoute.params.id);
  }

  public allPreviewItems() {
    this.previewItems = readPreviewItems(this.$store);
  }

  get previewHeader() {
    return readPreviewHeader(this.$store);
  }
}
