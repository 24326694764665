
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
} from "@/interfaces";
import {
  readMetrics,
  readModels,
  readItems,
  readLabelsByIds,
  readPreviewItems,
  readPreviewHeader,
  readItem,
  readModel,
  readPredictions,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readFirstLabelContainer,
  readTarget,
  readLabels,
} from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetConfusionMatrixItems,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetPredictions,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetRecommendation,
} from "@/store/model/actions";

import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ItemPreviewConfusionMatrix from "@/components/ItemPreviewConfusionMatrix.vue";

@Component({
  components: {
    ItemPreview,
    ItemPreviewConfusionMatrix,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
  },
})
export default class Preview extends Vue {
  public id: number = 1;
  public metricThreshold: number = 0.95;
  public preview: boolean = true;
  public loading: boolean = false;
  public targetItems: IItemUpdate[] = [];

  get isAdmin() {
    return readHasAdminAccess;
  }

  public async mounted() {
    await this.getConfusionMatrixItems();
  }

  public async getConfusionMatrixItems() {
    const train =
      this.$router.currentRoute.query.train === undefined ||
      this.$router.currentRoute.query.train === "false"
        ? false
        : true;

    await dispatchGetConfusionMatrixItems(this.$store, {
      modelId: +this.$router.currentRoute.params.id,
      actualLabelId: +this.$router.currentRoute.query.actual,
      predictedLabelId: +this.$router.currentRoute.query.predicted,
      threshold: +this.$router.currentRoute.query.threshold,
      train,
    }).then(() => {
    });
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  get actual() {
    return this.labelsByIds[+this.$router.currentRoute.query.actual];
  }

  get predicted() {
    return this.labelsByIds[+this.$router.currentRoute.query.predicted];
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelsByIds() {
    return readLabelsByIds(this.$store)(+this.$router.currentRoute.params.id);
  }

  get previewItems() {
    console.log(readPreviewItems(this.$store));
    return readPreviewItems(this.$store);
  }

  get previewHeader() {
    return readPreviewHeader(this.$store);
  }
}
