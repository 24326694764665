
import { Component, Vue } from "vue-property-decorator";
import { IPaidPlanCreate, IFreePlanCreate } from "@/interfaces";
import { dispatchGetUsers, dispatchUpdateUser } from "@/store/admin/actions";
import { readAdminOneUser } from "@/store/admin/getters";
import { readToken } from "@/store/main/getters";
import { api } from "@/api";

@Component
export default class ManagePlans extends Vue {
  public validPaid = false;
  public validFree = false;
  public plans: [] = [];
  // FREE
  public freeName: string | null = null;
  public freeNickname: string | null = null;
  public freeActive: boolean = true;
  public freeMaxNumInferences: number | null = null;
  public freeNumModels: number | null = null;
  public freePrivate: boolean = false;
  public freePremium: boolean = false;
  public freeNumDatasets: number | null = null;
  public freeNumRowsPerDataset: number | null = null;
  // PAID
  public paidName: string | null = null;
  public paidNickname: string | null = null;
  public paidActive: boolean = true;
  public paidMaxNumInferences: null = null;
  public paidNumModels: null = null;
  public paidPrivate: boolean = false;
  public paidPremium: boolean = false;
  public paidInferencePrice: null = null;
  public paidPeriodPrice: null = null;
  public paidPeriod: string = "month";
  public paidNumDatasets: null = null;
  public paidNumRowsPerDataset: null = null;

  public planError: string = "";
  public loading: boolean = false;
  public createFree: boolean = true; // picker for what type of plan u want to create

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.getAllPlans();
  }

  private async getAllPlans() {
    const res = await api.getAdminSubscriptionPlans(this.token);
    this.plans = res.data;
  }

  get headers() {
    return [
      {
        text: "Subscription plans",
        align: "left",
        sortable: false,
        value: "name",
      },
      { text: "Id", value: "id" },
      { text: "Active", value: "active" },
      { text: "Nickname", value: "nickname" },
      { text: "max_num_inf", value: "max_num_inf" },
      { text: "stripe_flat_price", value: "stripe_flat_price" },
      { text: "stripe_inference_price", value: "stripe_inference_price" },
      { text: "stripe_price_id_flat", value: "stripe_price_id_flat" },
      { text: "stripe_price_id_inference", value: "stripe_price_id_inference" },
      { text: "plan_period", value: "plan_period" },
      { text: "paid", value: "paid" },
      { text: "premium_model", value: "premium_model" },
      { text: "private", value: "private" },
      { text: "num_models", value: "num_models" },
      { text: "num_datasets", value: "num_datasets" },
      { text: "dataset_size", value: "dataset_size" },
    ];
  }

  get token() {
    return readToken(this.$store);
  }

  private async submitPaid() {
    this.planError = "";
    this.loading = true;
    try {
      if ((this.$refs.paidForm as Vue & { validate: () => boolean }).validate()) {
        const paidPlan: IPaidPlanCreate = {
          active: this.paidActive,
          name: this.paidName,
          nickname: this.paidNickname,
          max_num_inf: this.paidMaxNumInferences,
          num_models: this.paidNumModels,
          private: this.paidPrivate,
          stripe_inference_price: this.paidInferencePrice,
          stripe_flat_price: this.paidPeriodPrice,
          paid: true,
          premium_model: this.paidPremium,
          plan_period: this.paidPeriod,
          num_datasets: this.paidNumDatasets,
          dataset_size: this.paidNumRowsPerDataset,
        };
        const res = await api.createPaidSubscriptionPlan(this.token, paidPlan);
        await this.getAllPlans();
      }
      this.loading = false;
    } catch (err) {
      const error = err as any;
      this.planError = error.response.data.detail;
      console.log(error.response.data.detail);
      this.loading = false;
    }
  }

  private async submitFree() {
    this.planError = "";
    this.loading = true;
    try {
      if ((this.$refs.freeForm as Vue & { validate: () => boolean }).validate()) {
        const freePlan: IFreePlanCreate = {
          active: this.freeActive,
          name: this.freeName,
          nickname: this.freeNickname,
          max_num_inf: this.freeMaxNumInferences,
          num_models: this.freeNumModels,
          private: this.freePrivate,
          paid: false,
          premium_model: this.freePremium,
          num_datasets: this.freeNumDatasets,
          dataset_size: this.freeNumRowsPerDataset,
        };
        const res = await api.createFreeSubscriptionPlan(this.token, freePlan);
        await this.getAllPlans();
      }
      this.loading = false;
    } catch (err) {
      const error = err as any;
      this.planError = error.response.data.detail;
      console.log(error.response.data.detail);
      this.loading = false;
    }
  }
}
